/* Footer.css */

.footer {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgb(105, 105, 105,1) 100%);
  color: #f5f5f5;
    padding: 40px 0;
}

  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .footer-links,
  .footer-contact,
  .footer-logo {
    flex: 1;
    margin: 0 20px;
  }
  
  .footer-links h4,
  .footer-contact h4 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
    padding-bottom: 10px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #f5f5f5;
    text-decoration: none;
    transition: color 0.3s, transform 0.3s;
  }
  
  .footer-links a:hover {
    color: #f0a500;
    text-decoration: underline;
    transform: translateX(5px);
  }
  
  .footer-contact {
    display: flex;
    flex-direction: column;
  }
  
  .footer-contact p {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .footer-contact a {
    color: #f5f5f5;
    text-decoration: none;
    margin-left: 10px;
    transition: color 0.3s;
  }
  
  .footer-contact a:hover {
    color: #f0a500;
  }
  
  .footer-contact .icon {
    font-size: 22px;
    margin-right: 15px;
  }
  
  /* Home icon adjusted for larger screens */
  .footer-contact .icon.fa-home {
    font-size: 26px;
  }
  
  .footer-logo {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-logo img {
    max-width: 250px;
    height: auto;
    transition: transform 0.3s;
  }
  
  .footer-logo img:hover {
    transform: scale(1.05);
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .footer-bottom p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .footer-bottom a {
    color: #f5f5f5;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-bottom a:hover {
    color: #f0a500;
  }
  
  .footer-social {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .footer-social a {
    font-size: 24px;
    margin: 0 15px;
    color: #f5f5f5;
    transition: color 0.3s, transform 0.3s;
  }
  
  .footer-social a:hover {
    color: #f0a500;
    transform: scale(1.1);
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .footer-links {
      order: 1;
      margin-bottom: 20px;
    }
  
    .footer-logo {
      order: 2;
      text-align: center; /* Center the logo */
      margin: 0 auto;
    }
  
    .footer-contact {
      order: 3;
    }
  
    .footer-logo img {
      max-width: 120px; /* Reduce logo size for small screens */
    }
  
    .footer-contact p {
      font-size: 14px;
      margin-bottom: 8px;
    }
  
    /* Home icon size adjustment for small screens */
    .footer-contact .icon.fa-home {
      font-size: 22px;
    }
  }
  
  .footer-links button {
    background: none; /* Remove default background */
    border: none; /* Remove border */
    color: #f5f5f5; /* Inherit link color */
    text-decoration: none; /* Remove default text decoration */
    transition: color 0.3s, transform 0.3s;
    cursor: pointer; /* Change cursor to pointer */
    font: inherit; /* Use inherited font style */
    padding: 0; /* Remove default padding */
}

.footer-links button:hover {
    color: #f0a500; /* Hover color to match links */
    text-decoration: underline; /* Underline on hover */
    transform: translateX(5px); /* Shift slightly on hover */
}

.footer-links button:focus {
    outline: 2px solid #f0a500; /* Add focus outline for accessibility */
}
