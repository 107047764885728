/* RegistrationForm.css */
.booth {
  transition: fill 0.3s; /* Smooth transition for color change */
  cursor: pointer; /* Show hand cursor on hover */
}
.custom-select {
  position: relative;
  width: 100%;
}

.custom-select select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  appearance: none;
  background-color: white;
  color: black;
  font-size: 14px;
}

.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 3px 3px;
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
}

.custom-select:hover .custom-options {
  display: block;
}

.custom-option {
  padding: 8px;
  cursor: pointer;
}

.custom-option:hover {
  background-color: darkred;
  color: white;
}

.custom-options .custom-option.hovered {
  background-color: darkred;
  color: white;
}

/* RegistrationForm.css */

/* Add a class to the container to control the layout */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Display the form and map in a column on small screens */
    align-items: center; /* Center align on small screens */
  }

  .container > div {
    width: 100%; /* Set width to 100% for both form and map */
    margin: 0; /* Remove margin for both form and map */
  }

  .container > div:first-child {
    margin-bottom: 20px; /* Add margin between form and map on small screens */
  }
}

/* Rest of your existing CSS... */
