@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.notification-shake {
  animation: shake 0.5s ease-in-out infinite; /* Infinite animation */
  animation-play-state: running; /* Animation running by default */
}

.notification-shake:hover {
  animation-play-state: paused; /* Pause the animation on hover */
}
