
  
  /* For screens larger than or equal to 768px (e.g., tablets and desktops) */
  @media (min-width: 768px) {
    .form-menu-container {
      display: flex;
      flex-direction: row; /* Display components side by side */
      align-items: flex-start;

    }
  
    .event-menu {
      order: 1; /* Place EventMenu on the left */
      margin-right: 1rem; /* Add space between EventMenu and the form */
    }
  }
  
  