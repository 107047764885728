/* HelpButton.css */

/* Container for the SpeedDial */
.speed-dial-container {
    position: fixed; /* Make it fixed to the viewport */
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  /* Apply styles when the SpeedDial is active (open) */
  .fixed-speed-dial {
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
  }
  