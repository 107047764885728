
  

  

  

  .reservation-form-container2 {
    background-image: url('https://searchengineland.com/wp-content/seloads/2014/04/hotel-bell-customer-service-ss-1920.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: "fixed";
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: white; /* Text color */
  }
 

  
  button[type="submit"] {
    background-color: darkred;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: black;
  }
  
  /* Add more styling as needed */
  