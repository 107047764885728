/* src/ComingSoon.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html, body, #root {
  height: 100%;
  background-color: #FFFFFF; /* Keep the background clean and white */
}

img {
  max-width: 100%;
  height: auto;
}
